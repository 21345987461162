.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 80px;
}

.App-header {
  background-color: #550c73;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}
